<nav class="paginator" aria-label="Page navigation">
  <div class="row">
    <div class="col">
      All results: {{ pagination.count }}
    </div>
  </div>
  <div class="row justify-content-md-center">
    <div class="col">
      <ul class="pagination justify-content-md-center">
        <li class="page-item" [class.active]="currentPage === page" *ngFor="let page of pages">
          <a [routerLink]="[basePath]" [queryParams]="{page: page}" class="page-link">{{ page }}</a>
        </li>
      </ul>
    </div>

  </div>

</nav>
